/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
export default function access(initialState: { currentUser?: USERAPI.CurrentUser } | undefined) {
  const { currentUser } = initialState ?? {};

  const checkAccess = (permissionName: string) => {
    const { permissions = [] } = currentUser?.role || {};
    const _departments = permissions?.map((item: { name: string }) => {
      return item.name;
    });
    return _departments.includes(permissionName);
  };

  return {
    // 用户角色管理菜单权限
    'menu.user': checkAccess('user-accounts') || checkAccess('role-permissions'),

    // 账号管理
    'user-accounts': checkAccess('user-accounts'),
    // 组织架构编辑
    'edit-departments': checkAccess(
      'POST:api/admin/departments|PUT:api/admin/departments/{department}|DELETE:api/admin/departments/{department}',
    ),
    // 新增员工
    'create-user': checkAccess('POST:api/admin/users'),
    // 导入员工
    'import-user': checkAccess('POST:api/admin/users/import'),
    // 编辑员工
    'edit-user': checkAccess('PUT:api/admin/users/{user}'),
    // 删除员工
    'delete-user': checkAccess('DELETE:api/admin/users/{user}'),

    // 角色权限
    'role-permissions': checkAccess('role-permissions'),
    // 新增角色
    'create-role': checkAccess('POST:api/admin/role-permissions'),
    // 权限设置
    'edit-role': checkAccess('PUT:api/admin/role-permissions/{role}'),
    // 删除权限
    'delete-role': checkAccess('DELETE:api/admin/role-permissions/{role}'),

    // 流程管理菜单权限
    'menu.processes': checkAccess('add-processes') || checkAccess('processes'),

    // 新建流程
    'add-processes': checkAccess('add-processes'),
    // 创建流程
    'add-create-processes': checkAccess(
      'POST:api/admin/refunds|POST:api/admin/operation-processes/cancel-orders|POST:api/admin/operation-processes/urgent-orders',
    ),
    // 批量创建流程
    'add-import-processes': checkAccess('POST:api/admin/import-process'),

    // 批量新建退款流程
    'import-add-import-processes': checkAccess(
      'POST:api/admin/refunds/import/ladder-coupons|POST:api/admin/refunds/import/other-refunds',
    ),

    // 退款流程列表
    processes: checkAccess('processes'),
    // 操作流程列表
    'operation-processes': checkAccess('operation-processes'),

    // 导出-操作流程
    'export-operate': checkAccess('POST:api/admin/export/operate_create'),
    // 导出-退费流程
    'export-refund': checkAccess('POST:api/admin/export/refund_create'),
    // 删除导出任务
    'export-deleteList': checkAccess('DELETE:api/admin/export/delete/{id}'),
    // 下载导出任务
    'export-download': checkAccess('GET:api/admin/export/{processExports}/download_url'),
    // 导出任务列表
    'export-taskList': checkAccess('task-lists'),
  };
}
