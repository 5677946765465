import { featchDepartments, fetchSimpleUsers } from '@/services/user';
import { useModel } from '@umijs/max';
import { useState } from 'react';
import { flushSync } from 'react-dom';

export default () => {
  const [departmentList, setDepartmentList] = useState<USER.department[]>([]);
  const [departmentData, setDepartmentData] = useState<any[]>([]);
  const [roleList, setRoleList] = useState<any[]>([]);
  // 用户数据
  const [userSelectOption, setUserSelectOption] = useState<any[]>([]);
  // 部门数据
  const [departmentOption, setDepartmentOption] = useState<any[]>([]);

  const { initialState, setInitialState } = useModel('@@initialState');

  const fetchUserInfo = async () => {
    const userInfo = await initialState?.fetchUserInfo?.();
    if (userInfo) {
      flushSync(() => {
        setInitialState((s) => ({
          ...s,
          currentUser: userInfo,
        }));
      });
    }
  };

  // 获取所有用户的选择的选项
  const fetchUsersSelectOption = async () => {
    // 实时获取数据
    // if (!userSelectOption.length) {
    const { data } = await fetchSimpleUsers();
    const getParent = (parentList: any) => {
      let parentString = parentList.name ? [parentList.name] : [];
      if (parentList.parent_department) {
        parentString = getParent(parentList.parent_department).concat(parentString);
      }
      return parentString;
    };
    const _data = data.map((item: any) => {
      const departmentName = getParent(item.parent_department || {});
      if (item.department && item.department.name) {
        departmentName.push(item.department.name);
      }
      return {
        ...item,
        departmentName: departmentName.join('/'),
      };
    });
    setUserSelectOption(_data);
    // }
  };

  /**
   * 获取组织架构的list
   * @param {USER.departmentResponse[]} departmentResponse 传入的组织架构列表
   */
  const extractStructure = (departmentResponse: USER.departmentResponse[]) => {
    let _departmentList: USER.department[] = [];
    departmentResponse.forEach((item: any) => {
      if (
        item.all_sub_departments &&
        Array.isArray(item.all_sub_departments) &&
        item.all_sub_departments.length !== 0
      ) {
        _departmentList = _departmentList.concat(extractStructure(item.all_sub_departments));
      }
      _departmentList.push({
        id: item.id,
        name: item.name,
        parent_id: item.parent_id,
        content: item,
      });
    });
    return _departmentList;
  };

  // 将组织架构数据转化为树形结构数据
  const formatDepartmentDataNotSet = (departmentResponse: USER.departmentResponse[]) => {
    const formatDepartment: any = (departmentResponse: any[]) => {
      return departmentResponse.map((item) => {
        const { name, id, level, all_sub_departments, parent_id } = item;
        if (Array.isArray(all_sub_departments)) {
          return {
            title: name,
            label: name,
            key: id,
            value: id,
            level,
            children: formatDepartment(all_sub_departments),
            parent_id,
          };
        }
        return {
          title: name,
          label: name,
          key: id,
          value: id,
          level: level,
          parent_id,
        };
      });
    };

    const _departmentData = formatDepartment(departmentResponse);
    return _departmentData;
  };

  // 将组织架构数据转化为树形结构数据
  const formatDepartmentData = (departmentResponse: USER.departmentResponse[]) => {
    const _departmentData = formatDepartmentDataNotSet(departmentResponse);
    setDepartmentData(_departmentData);
    return _departmentData;
  };

  const findParent = (parentId: number) => {
    let parentIdList: number[] = [];
    const parent = departmentList.find((item) => parentId === item.id);
    if (parent && parent.id) {
      parentIdList.push(parent.id);
      if (parent.parent_id) {
        parentIdList = findParent(parent.parent_id).concat(parentIdList);
      }
    }
    return parentIdList;
  };

  // 获取部门数据并格式化
  const fetchDepartmentData = async () => {
    // 实时获取数据
    // if (!departmentOption.length) {
    const departmentResponse = await featchDepartments();
    const _departmentOption = formatDepartmentData(departmentResponse);
    setDepartmentOption(_departmentOption);
    const _departmentList = extractStructure(departmentResponse);
    setDepartmentList(_departmentList);
    return _departmentOption;
    // } else {
    //   const _departmentOption = formatDepartmentData(departmentOption);
    //   return _departmentOption;
    // }
  };

  return {
    departmentList,
    departmentData,
    extractStructure,
    formatDepartmentData,
    roleList,
    setRoleList,
    fetchUsersSelectOption,
    userSelectOption,
    departmentOption,
    fetchDepartmentData,
    findParent,
    fetchUserInfo,
    formatDepartmentDataNotSet,
  };
};
