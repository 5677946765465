/*
 * @Author: xieliangkai@lf-network.com xieliangkai@lf-network.com
 * @Date: 2024-05-09 19:23:11
 * @LastEditors: xieliangkai@lf-network.com xieliangkai@lf-network.com
 * @LastEditTime: 2024-06-04 18:27:55
 * @FilePath: /teamoa-frontend/src/services/flows/handleFlows.ts
 * @Description: 操作类流程相关api
 */
import apiFormat from '@/utils/apiFormat';

import { request } from '@umijs/max';

type CancelReason = 1 | 2 | 3 | 4;

interface createFlowsParams {
  initiate_user_id: number;
  owner_user_id: number;
  order_id: string;
  cancel_reason: CancelReason;
  refund_amount: number;
  append_file: string;
  describe: string;
  confirm_codes: [];
}
/**
 *
 * @description 新建取消流程
 * @param {createFlowsParams} params
 * @return {Promise}
 */
function postFlowCancleOrder(params: createFlowsParams[]): Promise<any> {
  return request('/api/admin/operation-processes/cancel-orders', {
    method: 'POST',
    data: params,
  }).then((res) => {
    if (res.code === 200) {
      return Promise.resolve({
        type: 'success',
        code: res.code,
        title: '成功',
        message: '流程新建成功!',
      });
    } else {
      if (res.detail) {
        return Promise.reject({
          type: 'error',
          code: res.code,
          title: res.detail?.title,
          message: res.detail?.content,
          process_id: res.detail?.process_id,
        });
      } else {
        return Promise.reject({
          type: 'error',
          code: res.code,
          title: '',
          message: res.message,
        });
      }
    }
  });
}

interface UrgentParams {
  initiate_user_id: number;
  owner_user_id: number;
  order_ids: string;
  cancel_reason: number;
  append_file: string;
  describe: string;
}

/**
 *
 * @description 新建加急订单流程
 * @param {UrgentParams} params
 * @return {Promise}
 */
function postFlowUrgentOrder(params: UrgentParams[]): Promise<any> {
  return request('/api/admin/operation-processes/urgent-orders', {
    method: 'POST',
    data: params,
  }).then((res) => {
    if (res.code === 200) {
      return Promise.resolve({
        type: 'success',
        code: res.code,
        title: '成功',
        message: '流程新建成功!',
      });
    } else {
      return Promise.reject(res);
    }
  });
}

/**
 *
 * @description 获取流程列表
 * @param {*} [data]
 * @param {Record<string, any>} [options]
 * @return {*}
 */
async function getFlowList(data?: any, options?: Record<string, any>) {
  const { include, data_type, ...clearParam } = apiFormat.clearFalseValue(data || {});
  const _params = apiFormat.getTableProGetParams(clearParam || {});
  const response = await request<any>('/api/admin/operation-processes', {
    method: 'GET',
    params: { ..._params, include, data_type },
    ...(options || {}),
  });
  return apiFormat.tableProResponseFormat(response);
}

/**
 *
 * @description 获取操作流程详情
 * @param {string} id
 * @return {*}
 */
function getFlowDetail(id: string) {
  return request<any>(`/api/admin/operation-processes/${id}`, {
    method: 'GET',
  })
    .then((res: any) => {
      if (res.code === 200) {
        return Promise.resolve(res.data);
      }
      return Promise.reject(res);
    })
    .catch((err: any) => {
      return Promise.reject(err);
    });
}

export interface ActionParams {
  items: {
    id: string | number;
    confirm_amount?: number;
  };
  action: 'reject' | 'pass' | 'void';
  reject_reason?: string;
}
/**
 *
 * @description 操作流程-取消订单流程-操作api
 * @param {ActionParams} params
 * @return {*}  {Promise<any>}
 */
function putFlowAction(params: ActionParams): Promise<any> {
  return request<any>('/api/admin/operation-processes/approval', {
    method: 'PUT',
    data: params,
  });
}

/**
 *
 * @description 操作流程是否需要再次确认
 * @param {{ ids: [] }} params
 * @return {*}  {Promise<any>}
 */
function postConfirmCheck(params: { ids: [] }): Promise<any> {
  return request<any>('/api/admin/operation-processes/confirm-check', {
    method: 'POST',
    data: params,
  });
}
/**
 *
 * @description 检查用户是否有权限发起流程
 * @return {*}  {Promise<any>}
 */
function getHandleAccess(id: number | string): Promise<any> {
  return request<any>(`/api/admin/operation-processes/check-user?responseId=${id}`, {
    method: 'GET',
  });
}

/**
 *
 * @description 流程作废
 * @param {{ ids: [] }} params
 * @return {*}  {Promise<any>}
 */
function postCancelProcess(params: { ids: [] }): Promise<any> {
  return request<any>('/api/admin/operation-processes/cancel-process', {
    method: 'POST',
    data: params,
  });
}

/**
 *
 * @description 操作流程-复合订单查询
 * @param {string} id
 * @return {*}
 */
function getMixOrder(id: string) {
  return request<any>(`/api/admin/mix-orders?filter[ids]=${id}`, {
    method: 'GET',
  });
}

export interface CancelReasonData {
  data: { key: string; value: string }[];
}

/**
 *
 * @description 获取取消原因
 * @return {*}  {Promise<any>}
 */
function getCancelReason(): Promise<CancelReasonData> {
  return request(`/api/admin/cancel-reason?type=1`, {
    method: 'GET',
  });
}
/**
 *
 * @description 获取加急类型
 * @return {*}
 */
function getUrgentType() {
  return request(`/api/admin/cancel-reason?type=2`, {
    method: 'GET',
  });
}

export default {
  postFlowCancleOrder,
  postFlowUrgentOrder,
  getFlowList,
  putFlowAction,
  postConfirmCheck,
  getHandleAccess,
  postCancelProcess,
  getMixOrder,
  getFlowDetail,
  getCancelReason,
  getUrgentType,
};
