import { AvatarDropdown, AvatarName } from '@/components';
// import { LinkOutlined } from '@ant-design/icons';
import type { Settings as LayoutSettings, MenuDataItem } from '@ant-design/pro-components';
// import { SettingDrawer } from '@ant-design/pro-components';
import avatar from '@/assets/avatar.jpeg';
import logo from '@/assets/logo.svg';
import { ReactComponent as MenuProcessIcon } from '@/assets/menuProcessIcon.svg';
import { ReactComponent as MenuUserIcon } from '@/assets/menuUserIcon.svg';
import Icon, { ExportOutlined } from '@ant-design/icons';
import type { RunTimeLayoutConfig } from '@umijs/max';
import { history, Link } from '@umijs/max';
import { ConfigProvider, message, Result } from 'antd';
// import moment from 'moment';
import MenuItem from '@/components/MenuItem';

import defaultSettings from '../config/defaultSettings';
import { errorConfig } from './requestErrorConfig';
import { fetchConfig, queryCurrentUser, todoFlowsCountAPI } from './services/login';
import { checkUser, isRouterWhitePass, tologin } from './utils/utils';

const disablePath = '/disable';

const checkUserDisable = (is_on: number) => {
  if (is_on === 0) {
    history.replace('/disable');
  } else {
    const { location } = history;
    if (location.pathname === disablePath) {
      history.replace('/');
    }
  }
};

/**
 *
 * @description umiUse-patchRoutes 路由fix
 * @export
 * @param {*} { routes }
 */
export function patchRoutes({ routes }: any) {
  const iconPath: Record<string, React.JSX.Element> = {
    '/process': <Icon component={MenuProcessIcon} />,
    '/users': <Icon component={MenuUserIcon} />,
    '/exportTask': <ExportOutlined />,
  };
  const replacePath = Object.keys(iconPath);
  for (let key in routes) {
    if (replacePath.includes(routes[key].path)) {
      routes[key].icon = iconPath[routes[key].path];
    }
  }
}

/**
 * @description 全局初始状态设置
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * @return {*}  {(Promise<{
 *   settings?: Partial<LayoutSettings>;
 *   currentUser?: USERAPI.CurrentUser;
 *   loading?: boolean;
 *   fetchUserInfo?: () => Promise<USERAPI.CurrentUser | undefined>;
 *   fetchConfigInfo?: () => Promise<any>;
 *   fetchToDoCount?: () => Promise<any>;
 *   config?: any;
 *   toDoCount?: number;
 * }>)}
 */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: USERAPI.CurrentUser;
  loading?: boolean;
  fetchUserInfo?: () => Promise<USERAPI.CurrentUser | undefined>;
  fetchConfigInfo?: () => Promise<any>;
  fetchToDoCount?: () => Promise<any>;
  config?: any;
  toDoCount?: number;
}> {
  const fetchUserInfo = async () => {
    try {
      const { data: msg } = await queryCurrentUser();
      localStorage.setItem('OA_USER_NAME', msg.email);
      return msg;
    } catch (error) {
      // history.push(loginPath);
    }
    return undefined;
  };

  const fetchConfigInfo = async () => {
    try {
      const { data: msg } = await fetchConfig();
      return msg;
    } catch (error) {
      // history.push(loginPath);
    }
    return undefined;
  };

  const fetchToDoCount = async () => {
    try {
      const { count = 0 } = await todoFlowsCountAPI();
      return count;
    } catch (error) {
      // history.push(loginPath);
    }
    return undefined;
  };
  if (isRouterWhitePass()) {
    return {
      fetchUserInfo,
      fetchConfigInfo,
      fetchToDoCount,
      settings: defaultSettings as Partial<LayoutSettings>,
    };
  }

  // 如果已经登录执行
  const isLogin = checkUser();
  if (isLogin) {
    const [currentUser, config, toDoCount] = await Promise.all([
      fetchUserInfo(),
      fetchConfigInfo(),
      fetchToDoCount(),
    ]);
    return {
      fetchUserInfo,
      fetchConfigInfo,
      fetchToDoCount,
      currentUser,
      config,
      toDoCount,
      settings: defaultSettings as Partial<LayoutSettings>,
    };
  } else {
    message.warning('您还未登录，请先登录！');
    tologin();
  }
  return {
    fetchUserInfo,
    fetchConfigInfo,
    fetchToDoCount,
    settings: defaultSettings as Partial<LayoutSettings>,
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
/**
 *
 *
 * @param {*} {
 *   initialState,
 * }
 * @return {*}
 */
export const layout: RunTimeLayoutConfig = ({
  initialState,
}: //  setInitialState
any) => {
  return {
    // actionsRender: () => [<Question key="doc" />],
    avatarProps: {
      src: avatar,
      title: <AvatarName />,
      render: (_, avatarChildren) => {
        return <AvatarDropdown>{avatarChildren}</AvatarDropdown>;
      },
    },
    waterMarkProps: {
      content: initialState?.currentUser?.name,
      fontColor: 'rgba(227,227,227,0.2)',
    },
    // footerRender: () => <Footer />,
    onPageChange: () => {
      const islogin = checkUser();
      // 白名单判断
      if (isRouterWhitePass()) {
        return;
      }
      // 如果处于登录状态判断用户是否禁用
      if (islogin) {
        checkUserDisable(initialState?.currentUser?.is_on || 0);
      } else {
        message.warning('您还未登录，请先登录！');
        tologin();
      }
    },
    breadcrumbProps: {
      itemRender: (route) => {
        const { href = '', title = '' } = route;
        if (href) {
          return <Link to={href}>{title}</Link>;
        }
      },
    },
    bgLayoutImgList: [
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/D2LWSqNny4sAAAAAAAAAAAAAFl94AQBr',
        left: 85,
        bottom: 100,
        height: '303px',
      },
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/C2TWRpJpiC0AAAAAAAAAAAAAFl94AQBr',
        bottom: -68,
        right: -45,
        height: '303px',
      },
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/F6vSTbj8KpYAAAAAAAAAAAAAFl94AQBr',
        bottom: 0,
        left: 0,
        width: '331px',
      },
    ],
    links: [],
    menuHeaderRender: undefined,
    postMenuData: (menuData: MenuDataItem[]) => {
      //  Dev组件过滤
      const url = window.location.pathname;
      const isDevPath = url.split('/')[1] === 'Dev';
      const newData = menuData.filter((item) => {
        if (isDevPath) {
          return item.isDevPath;
        } else {
          return !item.isDevPath;
        }
      });
      return newData;
    },
    menuItemRender: (menuItemProps, defaultDom) => {
      return <MenuItem path={menuItemProps.path || '/'}>{defaultDom}</MenuItem>;
    },
    // 自定义 403 页面
    unAccessible: (
      <Result
        // extra={<Button onClick={()=>history.push('/tickets/system')} type="primary">回到首页</Button>}
        status="403"
        subTitle="请联系管理员开通"
        title="暂无权限"
      />
    ),
    // 增加一个 loading 的状态
    childrenRender: (children) => {
      // if (initialState?.loading) return <PageLoading />;
      return (
        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorTextDisabled: '#222222',
              },
              Select: {
                colorTextDisabled: '#222222',
              },
            },
          }}
        >
          {children}
        </ConfigProvider>
      );
    },
    ...initialState?.settings,
    logo,
  };
};

/**
 * @name request 配置，可以配置错误处理
 * 它基于 axios 和 ahooks 的 useRequest 提供了一套统一的网络请求和错误处理方案。
 * @doc https://umijs.org/docs/max/request#配置
 */
export const request = {
  ...errorConfig,
};

export function onRouteChange({ location }) {
  // !NOTE 以下是在路由切换时进行埋点
  if (localStorage.getItem(`OA_TRACKED_PATH_NAME: ${location.pathname}`)) {
    return;
  }

  localStorage.setItem(`OA_TRACKED_PATH_NAME: ${location.pathname}`, true);
  setTimeout(() => {
    window.lfq('track', 'pageView');
    localStorage.removeItem(`OA_TRACKED_PATH_NAME: ${location.pathname}`, true);
  }, 1000);
}
