import handleFlowsApi, { CancelReasonData } from '@/services/flows/handleFlows';
import { useCallback, useState } from 'react';

export default () => {
  const [cancelReason, setCancelReason] = useState<CancelReasonData['data']>([]);
  const [cancelReasonLoading, setCancelReasonLoading] = useState<boolean>(false);
  const [cancelReasonError, setCancelReasonError] = useState<any>(null);
  const [disableCancelReason, setDisableCancelReason] = useState<boolean>(true);

  const fetchCancelReason = useCallback(async () => {
    setCancelReasonLoading(true);
    try {
      const { data } = await handleFlowsApi.getCancelReason();
      setCancelReason(data);
      setDisableCancelReason(false);
    } catch (error) {
      setCancelReasonError(error);
    } finally {
      setCancelReasonLoading(false);
    }
  }, []);

  return {
    cancelReason,
    cancelReasonLoading,
    cancelReasonError,
    fetchCancelReason,
    disableCancelReason,
    setDisableCancelReason,
  };
};
