import { Link, useModel } from '@umijs/max';
import { Badge } from 'antd';
import React from 'react';
import styles from './index.less';

type MenuItemProps = {
  children: any;
  path: string;
};

const MenuItem: React.FC<MenuItemProps> = ({ path, children }) => {
  const { initialState } = useModel('@@initialState');
  const { toDoCount = 0 } = initialState || {};

  if (path === '/process/list') {
    return (
      <Link className={styles.menu} to={path || '/'}>
        {children}
        <Badge count={toDoCount} />
      </Link>
    );
  }
  return (
    <Link className={styles.menu} to={path || '/'}>
      {children}
    </Link>
  );
};

export default MenuItem;
