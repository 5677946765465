import { useModel } from '@umijs/max';
import { flushSync } from 'react-dom';

export default () => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const { config } = initialState || {};

  const translateConfig = (key: any, configKey: string) => {
    const translateConfigItem = config?.[configKey]?.find((item: { key: any }) => item.key === key);
    return translateConfigItem?.value || '';
  };

  // 获取待办数量
  const fetchToDoCount = async () => {
    const toDoCount = await initialState?.fetchToDoCount?.();
    if (config) {
      flushSync(() => {
        setInitialState((s) => ({
          ...s,
          toDoCount,
        }));
      });
    }
  };

  return {
    translateConfig,
    fetchToDoCount,
  };
};
