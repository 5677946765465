import { getAlioss, outLogin } from '@/services/login';
import { history } from '@umijs/max';
import OSS from 'ali-oss';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import moment from 'moment';
// import { stringify } from 'querystring';

export const cnNameReg = /^[\u4E00-\u9FA5a-zA-Z0-9-]+$/u;
export const enNameReg = /^[a-zA-Z]+$/;
export const passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])\S+$/;
export const emailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const noCnReg = /^[^\u4E00-\u9FA5]+$/;

// 获取OSS上的地址
export const getOSSUrl = (path = '') => `https://teamoa-apps.oss-cn-shenzhen.aliyuncs.com/${path}`;

// 获取oss的sts临时令牌
export const getOssClient = async () => {
  const ossClient = sessionStorage.getItem('ossClient');
  const getOss = (token: API.ALIOSS) =>
    new OSS({
      // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
      region: token.region,
      accessKeyId: token.AccessKeyId,
      accessKeySecret: token.AccessKeySecret,
      stsToken: token.SecurityToken,
      // 填写Bucket名称，例如examplebucket。
      bucket: token.bucket,
      // 刷新临时访问凭证。
      refreshSTSToken: async () => {
        const refreshToken = await getAlioss();
        sessionStorage.setItem('ossClient', JSON.stringify(token));
        return {
          accessKeyId: refreshToken.AccessKeyId,
          accessKeySecret: refreshToken.AccessKeySecret,
          stsToken: refreshToken.SecurityToken,
        };
      },
    });
  if (ossClient) {
    const token = JSON.parse(ossClient);
    if (token?.Expiration && dayjs().isBefore(token?.Expiration)) {
      return getOss(token);
    }
  }
  const token = await getAlioss();
  sessionStorage.setItem('ossClient', JSON.stringify(token));
  return getOss(token);
};

/**
 * 将当前的 url 保存,返回登陆页面
 */
export const tologin = async () => {
  localStorage.removeItem('api-token');
  // const { search, pathname } = window.location;
  const urlParams = new URL(window.location.href).searchParams;
  /** 此方法会跳转到 redirect 参数所在的位置 */
  const redirect = urlParams.get('redirect');
  // Note: There may be security issues, please note
  if (window.location.pathname !== '/user/login' && !redirect) {
    history.replace({
      pathname: '/user/login',
      // search: stringify({
      //   redirect: pathname + search,
      // }),
    });
  }
};

/**
 * 退出登录，并且将当前的 url 保存,返回登陆页面
 */
export const loginOut = async () => {
  await outLogin();
  tologin();
};

// 获取用户token
export const getToken = () => {
  const tokenStr = localStorage.getItem('api-token') as string;
  if (tokenStr) {
    const token = JSON.parse(tokenStr);
    return token;
  }
  return null;
};

// 格式化为filter
export const formatTableGetParams = (params: Record<string, any>) => {
  const filter = {} as any;
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      switch (key) {
        case 'current':
          filter.page = params[key];
          break;
        case 'pageSize':
          filter.per_page = params[key];
          break;
        default:
          filter[`filter[${key}]`] = params[key];
          break;
      }
    }
  }
  return filter;
};

// 格式化page和pageSize
export const formatTablePostParams = (params: Record<string, any>) => {
  const filter = {} as any;
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      switch (key) {
        case 'current':
          filter.page = params[key];
          break;
        case 'pageSize':
          filter.per_page = params[key];
          break;
        default:
          filter[key] = params[key];
          break;
      }
    }
  }
  return filter;
};

// 格式化为展示需要的数据格式
export const formatTableResponse = (response: Record<string, any>) => {
  const { data = [], meta = {} } = response;
  const responseFormat = {
    current: meta.current_page,
    data: data,
    pageSize: meta.per_page,
    total: meta.total,
    success: true,
  };
  return responseFormat;
};

// 清除空字符串、null和undefined
export const clearFalseValue = (compactParam: any) => {
  const _compactParam = cloneDeep(compactParam);
  for (const key in _compactParam) {
    if (
      isNil(_compactParam[key]) ||
      _compactParam[key] === '' ||
      (Array.isArray(_compactParam[key]) && _compactParam[key].length === 0)
    ) {
      delete _compactParam[key];
    }
  }
  return _compactParam;
};

export const stopPageTurning = (e: any) => {
  if (e.keyCode === 75) {
    e.stopPropagation();
  }
  if (e.keyCode === 74) {
    e.stopPropagation();
  }
};

export const item2Array = (data: Record<string, any>, keys: string[]) => {
  const param = cloneDeep(data);
  keys.forEach((item: string) => {
    if (param && param?.[item] && typeof param?.[item] === 'string') {
      param[item] = param?.[item].split(',');
    }
  });
  return param;
};

export const formatJson = (data: any[], keyMap: Record<string, string>) => {
  if (Array.isArray(data) && data.length && keyMap && typeof keyMap === 'object') {
    const keys = Object.keys(keyMap);
    return data.map((item: Record<string, any>) => {
      const coloneItem = cloneDeep(item);
      keys.forEach((keyItem: string) => {
        if (coloneItem[keyItem]) {
          coloneItem[keyMap[keyItem]] = coloneItem[keyItem];
          // delete coloneItem[keyItem];
        }
      });
      keys.forEach((keyItem: string) => delete coloneItem[keyItem]);
      return coloneItem;
    });
  }
  return [];
};

// 禁止input输入
export const handleKeyPress = (reg: RegExp, event: { key: any; preventDefault: () => void }) => {
  const inputValue = event.key;
  const pattern = reg; // 匹配中文、英文、数字的正则表达式
  if (!pattern.test(inputValue)) {
    event.preventDefault(); // 阻止输入
  }
};

// 使用Key翻译config
export const translateConfig = (key: any, config: any[]) => {
  const translateConfigItem = config.find((item) => item.key === key);
  return translateConfigItem.value;
};
// 路由白名单检测
export const isRouterWhitePass = (url?: string): boolean => {
  let passRouters: any[] = [];
  const { location } = history;
  const mergeUrl = url ? url : location.pathname;
  // TODO:临时方案
  if (process.env) {
    // 公共业务组件路由为白名单
    if (location.pathname.split('/').includes('Dev')) return true;
  }
  // if (location){}
  return passRouters.some((item) => {
    return item.path === mergeUrl;
  });
};

export const checkUser = () => {
  const loginPath = '/user/login';
  const { location } = history;
  const token = localStorage.getItem('api-token') as string;

  // 判断是否有token
  if (token) {
    const { expires_time } = JSON.parse(token);
    // 判断token是否失效
    if (moment(expires_time).isBefore(moment(new Date())) && location.pathname !== loginPath) {
      return false;
    }
  } else {
    return false;
  }
  return true;
};

// 净化金额
export const pureAmount = (amount: string) => {
  let pure = false;
  return amount.replace(/[^0-9.]/g, '').replace(/(\.)/g, () => (pure ? '' : (pure = true) && '.'));
};
