import { request } from '@umijs/max';

/** 退出登录接口 */
export async function outLogin(options?: Record<string, any>) {
  return request<Record<string, any>>('/api/admin/logout', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 登录接口 */

export async function login(body: USERAPI.LoginParams, options?: Record<string, any>) {
  return request<USERAPI.LoginResult>('/api/admin/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取当前的用户 */
export async function queryCurrentUser(options?: Record<string, any>) {
  return request<any>('/api/admin/me', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取项目配置信息 */
export async function fetchConfig(options?: Record<string, any>) {
  return request<any>('/api/admin/configs', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取阿里的token */
export async function getAlioss(options?: Record<string, any>) {
  const { data } = await request<{ data: API.ALIOSS }>('/api/admin/resource/ali-oss', {
    method: 'GET',
    ...(options || {}),
  });
  return data;
}

/** 待办数量 */
export async function todoFlowsCountAPI(options?: Record<string, any>) {
  const { data } = await request<{ data: Record<string, any> }>('/api/admin/todo-flows-count', {
    method: 'GET',
    ...(options || {}),
  });
  return data;
}
