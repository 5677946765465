import { clearFalseValue, formatTableGetParams, formatTableResponse } from '@/utils/utils';
import { request } from '@umijs/max';

//获取全部部门
export async function featchDepartments(options?: Record<string, any>) {
  return request<USERAPI.FeatchDepartmentsResult>('/api/admin/departments', {
    method: 'GET',
    params: {},
    ...(options || {}),
  });
}

//删除部门
export async function deleteDepartments({ id }: { id: number }, options?: Record<string, any>) {
  return request<any>(`/api/admin/departments/${id}`, {
    method: 'DELETE',
    ...(options || {}),
  });
}

/**
 *
 * @description 根据用户id获取指定level级别的部门
 * @param {number} id
 * @param {number} level
 * @return {*}  {Promise<any>}
 */
export function getDepartmentByLevel(id: number, level: number): Promise<any> {
  return request(`/api/admin/users/${id}/department-level/${level}`, {
    method: 'GET',
  });
}

//创建部门
export async function createDepartments(
  data: { parent_id: number; name: string },
  options?: Record<string, any>,
) {
  return request<any>('/api/admin/departments', {
    method: 'POST',
    data,
    ...(options || {}),
  });
}

// 编辑组织架构
export async function editDepartmentsAPI(
  id: number,
  data: { name: string },
  options?: Record<string, any>,
) {
  return request<any>(`/api/admin/departments/${id}`, {
    method: 'PUT',
    data,
    ...(options || {}),
  });
}

// 获取用户列表
export async function fetchUsers(data: any, options?: Record<string, any>) {
  const _params = formatTableGetParams(clearFalseValue(data));
  const response = await request<any>('/api/admin/users', {
    method: 'GET',
    params: _params,
    ...(options || {}),
  });
  return formatTableResponse(response);
}

// 获取所有用户列表
export async function fetchSimpleUsers(options?: Record<string, any>) {
  return await request<any>('/api/admin/simple-users', {
    method: 'GET',
    ...(options || {}),
  });
}

// 新增用户
export async function createUsers(data: any, options?: Record<string, any>) {
  return await request<any>('/api/admin/users', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}

// 批量新增用户
export async function importUsers(data: any, options?: Record<string, any>) {
  return await request<any>('/api/admin/users/import', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}

// 编辑用户
export async function editUsersAPI(id: string, data: any, options?: Record<string, any>) {
  return await request<any>(`/api/admin/users/${id}`, {
    method: 'PUT',
    data: clearFalseValue(data),
    ...(options || {}),
  });
}

// 批量新增用户
export async function deleteUsersAPI(id: string, options?: Record<string, any>) {
  return await request<any>(`/api/admin/users/${id}`, {
    method: 'DELETE',
    ...(options || {}),
  });
}
// 获取所有角色名称
export async function fetchAllRoleAPI(options?: Record<string, any>) {
  return await request<any>('/api/admin/all-role-permissions', {
    method: 'GET',
    ...(options || {}),
  });
}

// ---------------角色权限页面---------------

// 获取角色列表
export async function fetchRole(data?: any, include?: any, options?: Record<string, any>) {
  const _params = formatTableGetParams(data);
  const response = await request<any>('/api/admin/role-permissions', {
    method: 'GET',
    params: {
      ..._params,
      ...include,
    },
    ...(options || {}),
  });
  return formatTableResponse(response);
}

// 删除角色
export async function deleteRoleAPI(data: any, options?: Record<string, any>) {
  return await request<any>('/api/admin/role-permissions', {
    method: 'DELETE',
    params: data,
    ...(options || {}),
  });
}

// 获取所有权限
export async function permissionsAPI(options?: Record<string, any>) {
  return await request<any>('/api/admin/permissions', {
    method: 'GET',
    ...(options || {}),
  });
}

// 新增角色
export async function createRolePermissionsAPI(
  data: { name: string; permissions: any[]; department_ids: any[] },
  options?: Record<string, any>,
) {
  return await request<any>('/api/admin/role-permissions', {
    method: 'POST',
    data,
    ...(options || {}),
  });
}

// 删除角色
export async function deleteRolePermissionsAPI(id: string, options?: Record<string, any>) {
  return await request<any>(`/api/admin/role-permissions/${id}`, {
    method: 'DELETE',
    ...(options || {}),
  });
}

// 编辑角色权限
export async function editRolePermissionsAPI(
  id: string,
  data: { name: string; permissions: any[]; department_ids: any[] },
  options?: Record<string, any>,
) {
  return await request<any>(`/api/admin/role-permissions/${id}`, {
    method: 'PUT',
    data,
    ...(options || {}),
  });
}
