// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_2 from '/builds/Q_9R7ghN/0/lf/aftersell/teamoa-frontend/src/models/processModel';
import model_4 from '/builds/Q_9R7ghN/0/lf/aftersell/teamoa-frontend/src/.umi-production/plugin-initialState/@@initialState';
import model_1 from '/builds/Q_9R7ghN/0/lf/aftersell/teamoa-frontend/src/models/globalModal';
import model_3 from '/builds/Q_9R7ghN/0/lf/aftersell/teamoa-frontend/src/models/userModel';

export const models = {
model_2: { namespace: 'processModel', model: model_2 },
model_4: { namespace: '@@initialState', model: model_4 },
model_1: { namespace: 'globalModal', model: model_1 },
model_3: { namespace: 'userModel', model: model_3 },
} as const
